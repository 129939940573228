import styled from "styled-components";
import { useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css/bundle";
import DeadText from "./DeadText";
import DeadTitle from "./DeadTitle";
import ExampleItem from "./ExampleItem";
import { Next, Prev } from "../svg_components";
import { theme } from "../styles/Global";

const Examples = () => {
  const exampleList = [
    "k1.jpg",
    "k2.jpg",
    "k3.jpg",
    "k8.jpg",
    "k7.jpg",
    "k4.jpg",
    "k9.jpg",
    "k5.jpg",
    "k6.jpg",
    "k10.jpg",
  ];
  return (
    <ExamplesContainer>
      <DeadTitle>
        Insensitive robotic zombies
        <br />
        Coming to the world of BINANCE SMART CHAIN NFT
      </DeadTitle>
      <DeadText>
        The production of 2.000 Deadnought NFT from Hell was inspired by comic book classics and algorithmically randomly generated from 4 layers - 40 parts.
      </DeadText>
      <div className="examples-list">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={10}
          slidesPerView={4}
          slidesPerGroup={1}
          navigation={{
            nextEl: ".next",
            prevEl: ".prev",
          }}
          loop={true}
          loopFillGroupWithBlank={false}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
        >
          {exampleList.map((example) => (
            <SwiperSlide key={example}>
              <ExampleItem image={example} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="prev">
          <Prev />
        </div>
        <div className="next">
          <Next />
        </div>
      </div>
    </ExamplesContainer>
  );
};

export default Examples;

export const ExamplesContainer = styled.div`
  margin: 40px auto 40px auto;
  max-width: 1180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    margin-bottom: 20px;
  }

  p {
    max-width: 800px;
  }

  .examples-list {
    width: 100%;
    max-width: 1180px;
    margin: 40px 0px 20px 0px;
    position: relative;
    svg {
      width: 40px;
      height: 40px;
      fill: ${theme.colors.titleColor};
    }
    .prev {
      position: absolute;
      top: 50%;
      left: -20px;
      transform: translateY(-50%);
      cursor: pointer;
      background: transparent;
      z-index: 10;
      @media (max-width: 1240px) {
        left: 10px;
      }
    }
    .next {
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-50%);
      cursor: pointer;
      background: transparent;
      z-index: 10;
      @media (max-width: 1240px) {
        right: 10px;
      }
    }
  }
`;
