import React from "react";
import styled from "styled-components";
import { Twitter, Instagram, Linkedin, Discord } from "../svg_components";
import { theme } from "../styles/Global";

const TeamItem = (props) => {
  return (
    <TeamItemStyled>
      <img className="avatar" src={"/images/" + props.img} alt="avatar" />
      <p className="name">{props.name}</p>
      <span className="title">{props.title}</span>
      <ul className="social-links">
        {props.twitter && (
          <li>
            <a href={props.twitter} target="_blank" rel="noopener noreferrer">
              <Twitter />
            </a>
          </li>
        )}

        {props.instagram && (
          <li>
            <a href={props.instagram} target="_blank" rel="noopener noreferrer">
              <Instagram />
            </a>
          </li>
        )}

        {props.linkedin && (
          <li>
            <a href={props.linkedin} target="_blank" rel="noopener noreferrer">
              <Linkedin />
            </a>
          </li>
        )}

        {props.discord && (
          <li>
            <a href={props.discord} target="_blank" rel="noopener noreferrer">
              <Discord />
            </a>
          </li>
        )}
      </ul>
    </TeamItemStyled>
  );
};

const TeamItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .avatar {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 20px;
    background: red;
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1);
    @media (max-width: 768px) {
      width: 100px;
      height: 100px;
    }
  }
  .name {
    font-weight: 800;
    font-size: 16px;
    text-align: center;
    color: ${theme.colors.textColor};
    margin-bottom: 4px;
    @media (max-width: 425px) {
      height: 32px;
    }
  }
  .title {
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    color: ${theme.colors.textColor};
    height: 24px;
    margin-bottom: 4px;
  }
  .social-links {
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    li {
      margin-right: 5px;
      &:last-child {
        margin-right: 0;
      }
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export default TeamItem;
