import * as React from "react";

const SvgCircleTwitter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 51.491 51.49"
    {...props}
  >
    <g data-name="Group 13">
      <path
        data-name="Path 15"
        d="M25.746 0a25.745 25.745 0 1 0 25.745 25.745A25.774 25.774 0 0 0 25.746 0Zm0 49.537a23.792 23.792 0 1 1 23.793-23.792 23.819 23.819 0 0 1-23.793 23.792Z"
      />
      <path
        data-name="Path 16"
        d="M38.212 15.415a10.857 10.857 0 0 1-3.437 1.315 5.419 5.419 0 0 0-9.228 4.939 15.363 15.363 0 0 1-11.16-5.659 5.422 5.422 0 0 0 1.675 7.231 5.41 5.41 0 0 1-2.453-.678 5.42 5.42 0 0 0 4.343 5.378 5.4 5.4 0 0 1-2.444.092 5.415 5.415 0 0 0 5.058 3.761 10.884 10.884 0 0 1-8.018 2.241A15.4 15.4 0 0 0 36.241 20.36a10.955 10.955 0 0 0 2.7-2.8 10.827 10.827 0 0 1-3.109.853 5.424 5.424 0 0 0 2.38-2.998Z"
      />
    </g>
  </svg>
);

export default SvgCircleTwitter;
