import styled from "styled-components";
import { theme } from "../styles/Global";

const FeaturesItem = (props) => {
  return (
    <FeaturesItemStyled>
      <h3 className="title">
        <pre>{props.title}</pre>
      </h3>
      {props.icon}
    </FeaturesItemStyled>
  );
};

export default FeaturesItem;

const FeaturesItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  svg {
    width: 80px;
    height: 80px;
  }

  .title {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: ${theme.colors.titleColor};
  }
`;
