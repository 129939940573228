import styled from "styled-components";

const ExampleItem = (props) => {
  return (
    <ExampleItemStyled>
      <img src={`images/${props.image}`} alt="" />
    </ExampleItemStyled>
  );
};

export default ExampleItem;

export const ExampleItemStyled = styled.div`
  width: 200px;
  height: 200px;
  border: 8px solid #7a5f5f;
  border-radius: 20px;
  margin: 0px auto;
  img {
    border-radius: 12px;
    object-fit: cover;
    width: 184px;
    height: 184px;
  }
`;
