import * as React from "react";
const SvgBsc = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 83.547 83.546"
    {...props}
  >
    <g data-name="Group 6">
      <path
        data-name="Path 12"
        d="M0 41.773A41.773 41.773 0 1 0 41.773 0 41.773 41.773 0 0 0 0 41.773"
        fill="#040405"
      />
      <path
        data-name="Path 13"
        d="M47.381 61.016v6.526l-5.69 3.347-5.522-3.347v-6.526l5.522 3.347ZM16.924 38.425l5.522 3.347v11.213l9.538 5.69v6.526l-15.06-8.869Zm49.532 0v17.906L51.228 65.2v-6.526l9.539-5.69V41.772Zm-15.228-8.869 5.69 3.347v6.527l-9.537 5.689v11.379l-5.523 3.347-5.522-3.347V45.119l-9.873-5.689v-6.527l5.69-3.347 9.538 5.69ZM26.462 44.115l5.522 3.347v6.523l-5.522-3.347Zm30.456 0v6.526l-5.522 3.347v-6.526ZM22.446 23.868l5.689 3.347-5.689 3.346v6.524l-5.522-3.347v-6.523Zm38.488 0 5.69 3.347v6.525l-5.69 3.347v-6.526l-5.522-3.346Zm-19.244 0 5.691 3.347-5.69 3.346-5.522-3.346Zm0-11.213 15.228 8.87-5.522 3.346-9.539-5.69-9.705 5.69-5.523-3.346Z"
        fill="#ffca84"
      />
    </g>
  </svg>
);
export default SvgBsc;
