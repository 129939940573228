import * as React from "react";

const SvgP2E = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 96.379 91"
    {...props}
  >
    <g data-name="Group 554" fill="#ffca84">
      <path
        data-name="Path 535"
        d="m9.386 15.423 10.819 42.189a1.614 1.614 0 0 0 1.562 1.216 1.638 1.638 0 0 0 .4-.051 1.615 1.615 0 0 0 1.163-1.967L12.925 16.233l9.2-2.072a1.615 1.615 0 1 0-.71-3.152l-10.821 2.432a1.617 1.617 0 0 0-1.208 1.978Z"
      />
      <path
        data-name="Path 536"
        d="M16.127 59.486a1.615 1.615 0 0 0 1.435-2.353l-13.771-26.8 4.417-2.272a1.615 1.615 0 1 0-1.476-2.873L.877 28.196a1.617 1.617 0 0 0-.7 2.175l14.509 28.235a1.616 1.616 0 0 0 1.441.88Z"
      />
      <path
        data-name="Path 537"
        d="m74.253 14.16 9.2 2.072-8.515 33.2a1.615 1.615 0 0 0 1.163 1.967 1.622 1.622 0 0 0 1.967-1.165l8.927-34.815a1.616 1.616 0 0 0-1.21-1.978l-10.819-2.437a1.615 1.615 0 1 0-.71 3.152Z"
      />
      <path
        data-name="Path 538"
        d="m95.501 28.198-5.855-3.008a1.615 1.615 0 0 0-1.478 2.873l4.419 2.272-14.479 28.177a1.615 1.615 0 1 0 2.873 1.476L96.2 30.373a1.617 1.617 0 0 0-.7-2.175Z"
      />
      <path
        data-name="Path 539"
        d="M89.835 68.118H76.158V55.024a1.615 1.615 0 0 0-1.615-1.615h-3.366V1.618A1.617 1.617 0 0 0 69.562 0h-42.24a1.617 1.617 0 0 0-1.615 1.618v59.276H8.894a1.615 1.615 0 0 0-1.615 1.615V84.43a1.615 1.615 0 0 0 1.615 1.615h21.921a1.615 1.615 0 0 0 1.615-1.615V62.51a1.615 1.615 0 0 0-1.615-1.615h-1.879V3.233h39.008v50.176H52.621a1.615 1.615 0 0 0-1.615 1.615v18.047H36.681a1.615 1.615 0 0 0-1.616 1.615v14.7a1.615 1.615 0 0 0 1.616 1.615h21.921a1.615 1.615 0 0 0 1.616-1.615v-3.39h7.44a1.5 1.5 0 0 0 .255.051h21.921a1.615 1.615 0 0 0 1.615-1.615v-14.7a1.615 1.615 0 0 0-1.614-1.614ZM29.2 75.44H10.51v-4.091H29.2Zm-18.69 7.376v-4.145H29.2v4.145Zm18.69-14.7H10.51v-3.991H29.2Zm59.019 7.322h-18.69v-4.091h18.69ZM54.236 64.023h18.69v4.093h-18.69Zm18.69-7.384v4.153h-18.69v-4.153Zm-18.69 14.709h12.061v4.091h-6.08v-.754a1.615 1.615 0 0 0-1.616-1.615h-4.366Zm2.75 4.954v4.093h-18.69v-4.093Zm-18.69 11.467v-4.143h18.69v4.143Zm21.921-9.1h6.08v4.093h-6.08Zm9.311 4.145v-4.145h18.69v4.145Z"
      />
      <path
        data-name="Path 540"
        d="M34.341 24.311v13.38h2.818l-.071-7.993 4.318 7.993h2.818v-13.38h-2.818l.071 7.993-4.318-7.993Z"
      />
      <path
        data-name="Path 541"
        d="M54.296 26.915v-2.6h-8.439v13.38h2.853v-5.46h5.049v-2.551H48.71v-2.766Z"
      />
      <path
        data-name="Path 542"
        d="M60.946 37.691V26.915h3.229v-2.6h-9.313v2.6h3.229v10.776Z"
      />
    </g>
  </svg>
);

export default SvgP2E;
