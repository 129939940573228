import styled from "styled-components";
import { theme } from "../styles/Global";
import DeadText from "./DeadText";

const Footer = () => {
  return (
    <FooterStyled>

      <ul className="sublogos">


        <li>
          <img className="logo" src="/images/logo_metal.png" alt="logo" />
        </li>

        <li>
          <a href="https://clashub.io/" target="_blank">
          <img className="clashub-logo" src="/images/clash_logo.png" />
          </a>
        </li>

      </ul>
      <div className="copyright">
        <h2>COPYRIGHT © 2023</h2>
        <h3>Deadnought</h3>
      </div>
    </FooterStyled>
  );
};

export default Footer;

export const FooterStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 1180px;
  margin: 40px auto 100px auto;
  .logo {
    width: 150px !important;
    height: auto;
    object-fit: cover;
  }
  .sublogos {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px 0px 0px 0px;
    gap: 40px;
    li {
      img {
        width: 175px;
      }
      .clashub-logo {
        width: 150px;
      }
    }
  }
  .copyright {
    margin-top: 40px;
    h2 {
      font-weight: 300;
      font-size: 17px;
      text-align: center;
      color: ${theme.colors.textColor};
    }
    h3 {
      font-weight: 700;
      font-size: 17px;
      text-align: center;
      color: ${theme.colors.textColor};
      margin-top: 6px;
    }
  }
`;
