import styled from "styled-components";
import { theme } from "../styles/Global";

const DeadTitle = (props) => {
  return <TitleStyled>{props.children}</TitleStyled>;
};

export default DeadTitle;

export const TitleStyled = styled.h1`
  font-weight: 900;
  font-size: 38px;
  line-height: 41px;
  text-align: center;
  text-transform: uppercase;
  color: ${theme.colors.titleColor};
`;
