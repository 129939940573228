import * as React from "react";

const SvgCircleDiscord = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 51.491 51.49"
    {...props}
  >
    <g data-name="Group 218">
      <path
        data-name="Path 15"
        d="M25.746 0a25.745 25.745 0 1 0 25.745 25.745A25.774 25.774 0 0 0 25.746 0Zm0 49.537a23.792 23.792 0 1 1 23.793-23.792 23.819 23.819 0 0 1-23.793 23.792Z"
      />
      <g data-name="Group 219">
        <path
          data-name="Path 128"
          d="M37.587 15.664a13.3 13.3 0 0 0-7.56-2.825l-.377.431a17.9 17.9 0 0 1 6.7 3.417 21.93 21.93 0 0 0-8.1-2.583 22.759 22.759 0 0 0-5.434.054 2.654 2.654 0 0 0-.457.054 20.2 20.2 0 0 0-6.107 1.695c-1 .457-1.587.78-1.587.78a18.152 18.152 0 0 1 7.076-3.524l-.269-.323a13.3 13.3 0 0 0-7.56 2.825 36.029 36.029 0 0 0-3.874 15.685s2.26 3.9 8.206 4.089c0 0 1-1.211 1.8-2.233a8.367 8.367 0 0 1-4.708-3.174s.269.188.753.457a.372.372 0 0 0 .108.081c.081.054.161.081.242.135a15.771 15.771 0 0 0 1.964.915 22.584 22.584 0 0 0 3.953 1.156 18.847 18.847 0 0 0 6.968.027 17.7 17.7 0 0 0 3.9-1.157 15.4 15.4 0 0 0 3.094-1.587 8.489 8.489 0 0 1-4.87 3.2c.807 1.022 1.776 2.179 1.776 2.179 5.946-.188 8.233-4.089 8.233-4.089a36.027 36.027 0 0 0-3.87-15.685ZM20.715 28.847a3 3 0 0 1 0-5.972 3 3 0 0 1 0 5.972Zm9.82 0a3 3 0 1 1 2.744-2.986 2.884 2.884 0 0 1-2.745 2.986Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgCircleDiscord;
