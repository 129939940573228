import styled from "styled-components";
import { theme } from "../styles/Global";
import { useState, useEffect } from "react";
import { Spinner } from "../svg_components";

const Banner = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const images = [
      "/images/red-flames.png",
      "/images/Deadnought-Logo.png",
      "/images/Deadnought-Character.png",
    ];
    cacheImage(images);
  }, []);

  const cacheImage = async (url) => {
    const promises = await url.map(async (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();

        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setIsLoading(false);
  };

  useEffect(() => {
    console.log(isLoading, "loading");
  }, [isLoading]);

  return isLoading ? (
    <div className="loading">
      <Spinner />
    </div>
  ) : (
    <BannerStyled>
      <img className="flame" src="/images/red-flames.png" alt="" />
      <img className="logo" src="/images/Deadnought-Logo.png" alt="" />
      <img className="char" src="/images/Deadnought-Character.png" alt="" />
    </BannerStyled>
  );
};

export default Banner;
export const BannerStyled = styled.div`
  position: relative;
  z-index: 2;
  height: 100vh;
  max-height: 600px;
  overflow: hidden;
  .flame {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%;
    min-width: 1180px;
  }
  .logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;

    @media (max-width: 480px) {
      width: 260px;
    }
  }
  .char {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
    z-index: 2;
  }
`;
