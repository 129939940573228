import * as React from "react";

const SvgFreeMint = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 79.078 97.919"
    {...props}
  >
    <g data-name="Group 552" fill="#ffca84">
      <g data-name="Group 551">
        <path
          data-name="Path 514"
          d="M39.38 97.44 3.149 80.992A5.375 5.375 0 0 1 .48 73.884l8.551-18.833a1.579 1.579 0 0 1 2.09-.786 2.369 2.369 0 0 0 1.959-4.313 1.579 1.579 0 0 1-.786-2.09l20.3-44.713A5.376 5.376 0 0 1 39.702.48l11.523 5.232a1.578 1.578 0 0 1 .926 1.46 6.318 6.318 0 0 0 10.543 4.788 1.577 1.577 0 0 1 1.71-.264l11.525 5.232a5.375 5.375 0 0 1 2.669 7.108l-20.3 44.711a1.578 1.578 0 0 1-2.09.786 2.368 2.368 0 1 0-1.957 4.313 1.579 1.579 0 0 1 .784 2.09l-8.551 18.835a5.375 5.375 0 0 1-7.104 2.669ZM11.344 57.585l-7.991 17.6a2.213 2.213 0 0 0 1.1 2.926l36.229 16.448a2.216 2.216 0 0 0 2.928-1.1l7.991-17.606a5.526 5.526 0 0 1 4.38-9.644l19.74-43.485a2.211 2.211 0 0 0-1.1-2.926l-10.642-4.833a9.477 9.477 0 0 1-14.945-6.784L38.392 3.35a2.213 2.213 0 0 0-2.926 1.1L15.727 47.939a5.528 5.528 0 0 1-4.38 9.646Z"
        />
        <g data-name="Group 549">
          <path
            data-name="Path 515"
            d="m24.96 59.683-3.45-1.567a1.58 1.58 0 1 1 1.306-2.876l3.45 1.567a1.58 1.58 0 1 1-1.306 2.876Z"
          />
          <path
            data-name="Path 516"
            d="m34.738 64.119-3.45-1.567a1.578 1.578 0 1 1 1.3-2.874l3.451 1.567a1.578 1.578 0 0 1-1.306 2.874Z"
          />
          <path
            data-name="Path 517"
            d="m44.515 68.558-3.451-1.567a1.58 1.58 0 0 1 1.306-2.876l3.45 1.567a1.579 1.579 0 1 1-1.3 2.876Z"
          />
        </g>
        <g data-name="Group 550">
          <path
            data-name="Path 518"
            d="M11.655 77.916a1.578 1.578 0 0 1-.786-2.09l5.549-12.22a1.579 1.579 0 1 1 2.876 1.3l-5.549 12.221a1.578 1.578 0 0 1-2.09.789Z"
          />
          <path
            data-name="Path 519"
            d="M16.543 80.134a1.579 1.579 0 0 1-.786-2.09l5.549-12.221a1.578 1.578 0 0 1 2.871 1.311L18.63 79.355a1.579 1.579 0 0 1-2.087.779Z"
          />
          <path
            data-name="Path 520"
            d="M21.43 82.355a1.58 1.58 0 0 1-.784-2.09l5.549-12.221a1.578 1.578 0 0 1 2.874 1.306l-5.547 12.22a1.581 1.581 0 0 1-2.092.785Z"
          />
          <path
            data-name="Path 521"
            d="M26.318 84.575a1.58 1.58 0 0 1-.784-2.092l5.547-12.22a1.58 1.58 0 0 1 2.876 1.306l-5.549 12.22a1.578 1.578 0 0 1-2.09.786Z"
          />
          <path
            data-name="Path 522"
            d="M31.206 86.79a1.577 1.577 0 0 1-.784-2.09l5.547-12.22a1.58 1.58 0 1 1 2.876 1.306l-5.549 12.22a1.576 1.576 0 0 1-2.09.784Z"
          />
          <path
            data-name="Path 523"
            d="M36.096 89.011a1.578 1.578 0 0 1-.786-2.09l5.549-12.22a1.579 1.579 0 1 1 2.876 1.3l-5.549 12.221a1.578 1.578 0 0 1-2.09.789Z"
          />
        </g>
      </g>
      <path
        data-name="Path 524"
        d="M34.415 43.712a1.578 1.578 0 0 1-.7-2.25l3.5-5.82-2.117-6.45a1.578 1.578 0 0 1 1.854-2.031l6.615 1.527 5.482-4.007a1.577 1.577 0 0 1 2.5 1.137l.592 6.764 5.5 3.975a1.577 1.577 0 0 1-.308 2.732l-6.25 2.653-2.077 6.464a1.579 1.579 0 0 1-2.694.552l-4.455-5.126-6.788.022a1.569 1.569 0 0 1-.654-.142Z"
      />
    </g>
  </svg>
);

export default SvgFreeMint;
