import * as React from "react";

const SvgPrev = (props) => (
  <svg
    data-name="Group 331"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 60.17 60.17"
    {...props}
  >
    <defs>
      <clipPath id="prev_svg__a">
        <path data-name="Rectangle 164" d="M0 0h60.17v60.17H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 330" clipPath="url(#prev_svg__a)">
      <path
        data-name="Path 195"
        d="M30.085 0A30.085 30.085 0 1 0 60.17 30.085 30.119 30.119 0 0 0 30.085 0m0 56.458a26.374 26.374 0 1 1 26.374-26.373 26.4 26.4 0 0 1-26.374 26.373"
      />
      <path
        data-name="Path 196"
        d="M37.144 13.062a2.476 2.476 0 0 0-3.5-.027L18.123 28.321a2.473 2.473 0 0 0-.026 3.5l15.522 15.762a2.474 2.474 0 0 0 3.526-3.472l-13.787-14 13.759-13.549a2.475 2.475 0 0 0 .027-3.5"
      />
    </g>
  </svg>
);

export default SvgPrev;
