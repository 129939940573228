import React from "react";
import styled from "styled-components";
import TeamItem from "./TeamItem";
import DeadTitle from "./DeadTitle";

const TeamContainer = (props) => {
  const [teamList, setTeamList] = React.useState([
    {
      id: 0,
      name: "Cüneyt Şenyavaş",
      position: "Founder & Creative Lead",
      image: "tiqdev.jpeg",
      instagram: "https://www.instagram.com/cuneytsenyavas/",
      twitter: "https://twitter.com/cuneytsenyavas",
      linkedin: "https://www.linkedin.com/in/cuneytsenyavas/",
      discord: "",
    },
    {
      id: 1,
      name: "Rıdvan Coşkun",
      position: "Founder",
      image: "tiqdev.jpeg",
      instagram: "https://www.instagram.com/ridvancoskun1/",
      twitter: "https://twitter.com/cridvan",
      linkedin: "https://www.linkedin.com/in/rıdvan-coşkun-54a8b743/",
      discord: "",
    },
    {
      id: 2,
      name: "tiqdev",
      position: "Developer",
      image: "tiqdev.jpeg",
      instagram: "",
      twitter: "https://twitter.com/tiqdev",
      linkedin: "",
      discord: "",
    },
    {
      id: 3,
      name: "The Fast Turk",
      position: "Art",
      image: "tiqdev.jpeg",
      instagram: "https://twitter.com/lodos2005",
      twitter: "",
      linkedin: "",
      discord: "",
    },
  ]);

  return (
    <TeamContainerStyled>
      <DeadTitle> DEADNOUGHT CORE TEAM </DeadTitle>
      <div className="team-list">
        {teamList.map((team) => (
          <TeamItem
            key={team.id}
            name={team.name}
            title={team.position}
            instagram={team.instagram}
            twitter={team.twitter}
            linkedin={team.linkedin}
            discord={team.discord}
            img={team.image}
          />
        ))}
      </div>
    </TeamContainerStyled>
  );
};

const TeamContainerStyled = styled.div`
  margin: 40px auto 40px auto;
  width: 90%;
  max-width: 1180px;
  padding: 20px 0px;

  .team-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 40px 20px;
    margin-top: 40px;
    &:not(:last-child) {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      margin-bottom: 40px;
    }
  }
  @media (max-width: 768px) {
    margin: 40px auto;
    .team-list {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }

  @media (max-width: 580px) {
    .team-list {
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    }
  }

  @media (max-width: 480px) {
    margin: 40px auto;
    .team-list {
      grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    }
  }
`;

export default TeamContainer;
