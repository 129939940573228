import React from "react";
import styled from "styled-components";
import { Avalanche, FreeMint, Omg, P2E } from "../svg_components";
import FeaturesItem from "./FeaturesItem";
import SvgBsc from "../svg_components/Bsc";

const FeaturesContainer = (props) => {
  const [featuresList, setFeaturesList] = React.useState([
    {
      id: 0,
      title: (
        <h3>
          BSC
          <br />
          NETWORK
        </h3>
      ),
      icon: <SvgBsc/>,
    },
    {
      id: 1,
      title: (
        <h3>
          FREE MINT
          <br />
          OPTIONS
        </h3>
      ),
      icon: <FreeMint />,
    },
    {
      id: 2,
      title: (
        <h3>
          SIGNED
          <br />
          COMIC PAGE
        </h3>
      ),
      icon: <Omg />,
    },
    {
      id: 3,
      title: (
        <h3>
          P2E
          <br />
          GAME
        </h3>
      ),
      icon: <P2E />,
    },
  ]);

  return (
    <FeaturesContainerStyled>
      <div className="features-list">
        {featuresList.map((feature) => (
          <FeaturesItem
            key={feature.id}
            title={feature.title}
            icon={feature.icon}
          />
        ))}
      </div>
    </FeaturesContainerStyled>
  );
};

const FeaturesContainerStyled = styled.div`
  margin: 40px auto 40px auto;
  width: 90%;
  max-width: 1180px;
  padding: 20px 0px;

  .features-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 40px 20px;
    margin-top: 0px;
    &:not(:last-child) {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      margin-bottom: 40px;
    }
  }
  @media (max-width: 768px) {
    margin: 40px auto;
    .features-list {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }

  @media (max-width: 580px) {
    .features-list {
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    }
  }

  @media (max-width: 480px) {
    margin: 40px auto;
    .features-list {
      grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    }
  }
`;

export default FeaturesContainer;
