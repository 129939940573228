import Banner from "../components/Banner";
import Clashub from "../components/Clashub";
import Examples from "../components/Examples";
import FeaturesContainer from "../components/FeaturesContainer";
import Follow from "../components/Follow";
import Footer from "../components/Footer";
import TeamContainer from "../components/TeamContainer";

const HomePage = () => {
  return (
    <div>
      <Banner />
      <Examples />
      <Follow />
      <FeaturesContainer />
      <Clashub />

      <Footer />
    </div>
  );
};

export default HomePage;

/*
 <TeamContainer />
*/
