import styled from "styled-components";
import { theme } from "../styles/Global";
import DeadText from "./DeadText";
import DeadTitle from "./DeadTitle";

const Clashub = () => {
  return (
    <ClashubStyled>
      <DeadTitle>
        BEST UTILITY IS P2E WITH <br /> CLASHUB PREMIUM PARTNERSHIP
      </DeadTitle>

      <div className="content">
        <div className="content_img">
          <img className="c1" src="/images/Card-1.png" alt="" />
          <img className="c2" src="/images/Card-2.png" alt="" />
          <img className="c3" src="/images/Card-3.png" alt="" />
        </div>
        <div className="content_text">
          <DeadText>
            The Deadnought NFT collection is a Premium Partnership with the Clash NFT Card Game. Your Deadnought NFT's will be converted into playing cards by Clashhub after to mint and you will be able to play the card game for earn to Clash tokens.
            <br />
            <br />
            Also, marketing support will be provided to Deadnought by Clashub.
            <br />
            <br />
            <a href="https://clashub.io" target="_blank">
              https://clashub.io
            </a>
          </DeadText>
        </div>
      </div>
    </ClashubStyled>
  );
};

export default Clashub;

export const ClashubStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1180px;
  margin: 40px auto;
  h1 {
    margin-bottom: 20px;
  }
  p {
    text-align: start !important;
  }
  @media (max-width: 768px) {
    background-color: rgba(206, 74, 74, 0.5) !important;
    padding: 40px;
    p {
      text-align: center !important;
      color: ${theme.colors.bodyBackground} !important;
    }
    h1 {
      color: ${theme.colors.bodyBackground} !important;
    }
  }

  @media (max-width: 480px) {
    padding: 20px;
  }

  @media (max-width: 425px) {
    .c2 {
      top: 20px !important;
      right: -30px !important;
    }
    .c1 {
      top: 20px !important;
    }
    .content {
      gap: 60px !important;
    }
    .c3 {
      right: -20px !important;
    }
  }

  .content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    height: auto;
    gap: 80px;
    @media (max-width: 1024px) {
      gap: 40px;
      .c1 {
        left: -40px;
      }
    }
    padding-top: 40px;
    @media (max-width: 768px) {
      flex-wrap: wrap;
      padding-top: 20px;
      .content_img {
        margin: 0px auto !important;
        height: 280px !important;
        img {
          transform: scale(0.8) !important;
        }
        .c1 {
          left: calc(50% - 100px) !important;
          transform: scale(0.8) translateX(-50%) !important;
        }
      }
    }

    .content_img {
      width: 80%;
      height: 350px;
      position: relative;
      margin: 0px 0px 0px auto;
      .c1 {
        position: absolute;
        width: 100%;
        max-width: 270px;
        z-index: 4;
      }
      .c2 {
        position: absolute;
        bottom: 0px;
        right: 5px;
        width: 100%;
        max-width: 200px;
        transform: rotate(10deg);
        z-index: 3;
      }
      .c3 {
        position: absolute;
        top: -20px;
        right: 15px;
        width: 100%;
        max-width: 150px;
        z-index: 2;
      }
    }

    .content_text {
      width: 100%;
      @media (max-width: 425px) {
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      a {
        color: ${theme.colors.bodyBackground} !important;
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
`;
