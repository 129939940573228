import { ThemeProvider } from "styled-components";
import GlobalStyles, * as global from "./styles/Global";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import NotFound from "./pages/NotFound";
import HomePage from "./pages/Home";


function App() {
 

  return (
    <>
      <BrowserRouter forceRefresh={false}>
        <ThemeProvider theme={global.theme}>
          <>
            <GlobalStyles />
            <>
              <Switch>
                <Route path="/" component={HomePage} exact />
                <Route path="/home" component={HomePage} />
                <Route component={NotFound} />
              </Switch>
            </>
          </>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
