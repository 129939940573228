import styled from "styled-components";
import { theme } from "../styles/Global";
import { CircleDiscord, CircleTwitter } from "../svg_components";

const Follow = () => {
  return (
    <FollowStyled>
      <div className="svgs">
        <a href="https://twitter.com/DeadNoughtNFT" target="_blank">
          <CircleTwitter />
        </a>
        <a href="https://discord.com/invite/theclashub">
          <CircleDiscord />
        </a>
      </div>
      <h1>Follow us on Twitter and Discord for whitelist and more news.</h1>
    </FollowStyled>
  );
};

export default Follow;

export const FollowStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 100%;
  max-width: 1180px;
  border-top: 1px solid ${theme.colors.textColor};
  border-bottom: 1px solid ${theme.colors.textColor};
  padding: 14px 0px;
  margin: 40px auto;
  position: relative;
  z-index: 3;

  @media (max-width: 768px) {
    background: rgba(255, 205, 132, 0.5);
    padding: 14px 40px;
    gap: 10px;
    flex-wrap: wrap;
    svg {
      fill: ${theme.colors.bodyBackground} !important;
    }
    h1 {
      color: ${theme.colors.bodyBackground} !important;
      text-align: center !important;
    }
  }
  .svgs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    svg {
      fill: ${theme.colors.textColor};
      width: 50px;
      height: 50px;
    }
  }
  h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    color: ${theme.colors.textColor};
  }
`;
