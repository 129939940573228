import styled from "styled-components";
import { theme } from "../styles/Global";

const DeadText = (props) => {
  return <TextStyled>{props.children}</TextStyled>;
};

export default DeadText;

export const TextStyled = styled.p`
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: ${theme.colors.textColor};
  a {
    color: ${theme.colors.titleColor};
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 480px) {
    font-size: 16px !important;
  }
`;
